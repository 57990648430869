import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Home from "./routes/Home";
import Spa from "./spa.js";
import { Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/authContext";
import ProtectedRoute from "./components/ProtectedRoute";
import OAuthCallback from "./routes/OAuthCallback";
import CssBaseline from "@mui/material/CssBaseline";
import { useThemeContext } from "./context/themeContext.js";

export default function App() {
  const { mode } = useThemeContext();

  const theme = createTheme({
    palette: {
      mode,
      primary: {
        main: "#00d37e",
        contrastText: "#fff",
      },
      secondary: {
        main: mode === "dark" ? "#1D3639" : "#f0f0f0",
        contrastText: mode === "dark" ? "#fff" : "#122425",
      },
      tertiary: {
        main: "#b1a7ec",
      },
      background: {
        default: mode === "dark" ? "#122425" : "#fff",
        paper: mode === "dark" ? "#122425" : "#fff",
      },
      text: {
        primary: mode === "dark" ? "#fff" : "#122425",
        // secondary: "#555555", // Secondary text color
      },
      status: {
        queued: {
          main: "#b1a7ec",
          contrastText: "#fff",
        },
        started: {
          main: "#9ee7b9",
          contrastText: "#333",
        },
        abandoned: {
          main: "#f27c75",
          contrastText: "#fff",
        },
        finished: {
          main: "#00d37e",
          contrastText: "#fff",
        },
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none", // Remove capitalisation
            boxShadow: "none", // Remove box shadow
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/oauth2/callback" element={<OAuthCallback />} />
          <Route
            path="/app/*"
            element={
              <ProtectedRoute>
                <Spa />
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </ThemeProvider>
  );
}
