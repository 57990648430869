import React from "react";
import {
  Box,
  Rating,
  Button,
  MenuItem,
  Menu,
  Divider,
  ListItemIcon,
} from "@mui/material";

const RatingFilterMenu = ({ value, onChange, onClear, anchorEl, onClose }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            overflow: "auto",
            maxHeight: 350, // Set a maximum height
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))", // adds shadow around menu
            mt: 1.5,
            "&::before": {
              // Creates triangular pointer visually connecting menu to anchor element
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        },
      }}
    >
      <Box>
        <MenuItem dense>
          <Rating
            name="rating"
            value={value}
            onChange={onChange}
            precision={0.5}
          />
        </MenuItem>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            mx: 2,
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={onClear}
            sx={{ mr: 1 }}
          >
            Clear
          </Button>
        </Box>
      </Box>
    </Menu>
  );
};

export default RatingFilterMenu;
