import React from "react";
import { Box, Card, CardActionArea, CardMedia } from "@mui/material";
import placeholderPoster from "../assets/placeholder-poster.png";
import AddIcon from "@mui/icons-material/Add";

export default function AddTile({ onClick }) {
  const cardContent = (
    <>
      <CardMedia
        component="img"
        sx={{
          width: "100%",
          objectFit: "contain",
          borderRadius: 1,
        }}
        image={placeholderPoster}
        // Had to use an actual image with a plain background (colour: #e3e3e3) rather than a skeleton
        // component because skeleton's have no intrinsic dimensions and it was causing problems with creating
        // a responsive collection dialog width
        alt={`placeholder poster`}
      />
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <AddIcon fontSize="large" />
      </Box>
    </>
  );
  return (
    <Card
      onClick={onClick}
      sx={{
        width: "100%",
        position: "relative",
        height: "100%",
      }}
    >
      {onClick ? <CardActionArea>{cardContent}</CardActionArea> : cardContent}
    </Card>
  );
}
