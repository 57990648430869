import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/authContext";

const ProtectedRoute = ({ children }) => {
  const { user, isCheckingAuth } = useAuth();

  if (isCheckingAuth) {
    return <div>Waiting for authorisation...</div>; // Or any other loading indicator
  }

  if (!user) {
    // User not logged in, redirect to home page
    return <Navigate to="/" replace />;
  }

  // User is logged in, allow access to the route
  return children;
};

export default ProtectedRoute;
