import React, { useState } from "react";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import MovieRoundedIcon from "@mui/icons-material/MovieRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import LiveTvRoundedIcon from "@mui/icons-material/LiveTvRounded";
import PodcastsRoundedIcon from "@mui/icons-material/PodcastsRounded";
import TheaterComedyRoundedIcon from "@mui/icons-material/TheaterComedyRounded";
import { useAuth } from "../context/authContext";
import { useTheme } from "@mui/material";

const actions = [
  { icon: <MovieRoundedIcon />, label: "Movie", disabled: false },
  { icon: <LiveTvRoundedIcon />, label: "TV show", disabled: true },
  { icon: <MenuBookRoundedIcon />, label: "Book", disabled: true },
  { icon: <PodcastsRoundedIcon />, label: "Podcast", disabled: true },
  { icon: <TheaterComedyRoundedIcon />, label: "Theatre show", disabled: true },
];

export default function AddContentButton({ onSelect }) {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <SpeedDial
      ariaLabel="SpeedDial controlled open example"
      sx={{
        position: "fixed",
        bottom: 21,
        right: 21,
      }}
      icon={<SpeedDialIcon />}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      FabProps={{
        sx: {
          bgcolor: (theme) =>
            theme.palette.status[user.preferences.addButton].main,
          "&:hover": {
            bgcolor: (theme) =>
              theme.palette.status[user.preferences.addButton].main,
          },
        },
      }}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.label}
          icon={action.icon}
          tooltipTitle={action.label}
          onClick={() => {
            handleClose();
            onSelect(action.label);
          }}
          disabled={action.disabled}
        />
      ))}
    </SpeedDial>
  );
}
