import React from "react";
import {
  Badge,
  Box,
  Card,
  CardActionArea,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import MetaDataTile from "../../components/MetaDataTile";
import MovieRoundedIcon from "@mui/icons-material/MovieRounded";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AddIcon from "@mui/icons-material/Add";
import Edit from "@mui/icons-material/Edit";
import SortIcon from "@mui/icons-material/Sort";
import AddTile from "../../components/AddTile";

export default function CollectionCover({ list, reverse = true, onClick }) {
  const tileWidth = { xs: "100px", md: "170px" };
  const spreadWidth = { xs: "-25px", md: "-60px" };
  return (
    <Card
      elevation={0}
      onClick={onClick}
      sx={{
        width: "100%",
        margin: "0 auto",
        // backgroundColor: (theme) => theme.palette.grey[100],
        // border: reverse ? "1px solid red" : undefined,
      }}
    >
      <CardActionArea sx={{ p: 1.5 }}>
        <Grid container spacing={2}>
          {" "}
          {/* Added spacing for better layout */}
          <Grid
            item
            xs={6}
            order={reverse ? 2 : 1}
            display="flex"
            flexDirection="column"
            gap={1}
          >
            <Typography
              variant="h6"
              align={reverse ? "left" : "right"}
              sx={{
                fontWeight: "bold",
                textWrap: "balance",
                pt: 0.5,
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,
              }}
            >
              {list.title}
            </Typography>
            <Typography
              variant="body2"
              align={reverse ? "left" : "right"}
              sx={{
                textWrap: "balance",
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 5,
              }}
            >
              {list.description}
            </Typography>
            <Stack
              direction={reverse ? "row" : "row-reverse"}
              spacing={1}
              alignItems="center"
              // justifyContent={reverse ? "flex-start" : "flex-end"}
              justifyContent="flex-start"
            >
              <MovieRoundedIcon />
              <Typography variant="body2">{list.movies.length}</Typography>
              <FavoriteIcon />
              <Typography variant="body2"> 69 </Typography>
              {/* {list.is_ranked && <SortIcon />} */}
            </Stack>
          </Grid>
          <Grid item xs={6} order={reverse ? 1 : 2}>
            {list.movies.length > 0 ? (
              <Stack
                direction={reverse ? "row-reverse" : "row"}
                spacing={spreadWidth}
                //   justifyContent={reverse ? "flex-end" : "flex-start"}
              >
                {list.movies.slice(0, 5).map((tile, index) => (
                  <MetaDataTile
                    tmdbId={tile.tmdbId}
                    key={tile.tmdbId}
                    width={tileWidth}
                    padding={{ xs: 0.5, md: 0.75 }}
                    zIndex={100 - index}
                  />
                ))}
              </Stack>
            ) : (
              <Stack
                direction={reverse ? "row-reverse" : "row"}
                spacing={spreadWidth}
                //   justifyContent={reverse ? "flex-end" : "flex-start"}
              >
                {Array(5)
                  .fill()
                  .map((_, index) => (
                    <MetaDataTile
                      key={index}
                      skeleton={true}
                      width={tileWidth}
                      padding={0.75}
                      zIndex={100 - index}
                    />
                  ))}
              </Stack>
            )}
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
}
