import React, { useState, useEffect } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CollectionsHeader from "../features/collections/Header.js";
import useApi from "../hooks/useApi";
import CollectionCover from "../features/collections/Cover.js";
import Collection from "../features/collections/Collection.js";
import InfiniteScroll from "react-infinite-scroll-component";
import Lottie from "lottie-react";
import arrowAnimation from "../assets/down-right-arrow.json";

export default function Collections({ toggleDrawer }) {
  const theme = useTheme();
  const { fetchCollections } = useApi();
  const [view, setView] = useState("grid");
  const [content, setContent] = useState("movies");
  const [sortBy, setSortBy] = useState("updated");
  const [sortDescending, setSortDescending] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [newCollectionId, setNewCollectionId] = useState(null);
  const [activeCollectionId, setActiveCollectionId] = useState(null);
  const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const handleContentChange = (newValue) => {
    setContent(newValue);
  };

  const handleSortByChange = (event, newSortBy) => {
    if (newSortBy !== null) {
      setSortBy(newSortBy);
    }
  };

  const toggleSortDescending = () => {
    setSortDescending(!sortDescending);
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const collectionsInfiniteQuery = useInfiniteQuery({
    queryKey: [
      "movie",
      "collections",
      "infinite",
      { sortBy, sortDescending, searchTerm },
    ],
    queryFn: ({ pageParam = 1 }) =>
      fetchCollections({
        pageParam,
        isDefault: false,
        perPage: 5,
        sortDescending,
        sortBy,
        searchTerm,
      }),
    getNextPageParam: (lastPage) => lastPage.nextPage,
    refetchOnMount: false,
  });

  const collectionsData =
    collectionsInfiniteQuery.data?.pages.flatMap((page) => page.items) || [];

  const activeCollection = collectionsData
    ? collectionsData.find(
        (collection) => collection.list_id === activeCollectionId
      )
    : null;

  useEffect(() => {
    if (
      newCollectionId &&
      collectionsData.some((list) => list.list_id === newCollectionId)
    ) {
      setActiveCollectionId(newCollectionId);
      setNewCollectionId(null);
    }
  }, [collectionsData, newCollectionId]);

  return (
    <>
      <CollectionsHeader
        content={content}
        onContentChange={handleContentChange}
        view={view}
        onViewChange={handleViewChange}
        sortBy={sortBy}
        onSortByChange={handleSortByChange}
        sortDescending={sortDescending}
        toggleSortDescending={toggleSortDescending}
        searchTerm={searchTerm}
        onSearchTermChange={handleSearchTermChange}
        toggleDrawer={toggleDrawer}
        setNewCollectionId={setNewCollectionId}
      />

      {collectionsInfiniteQuery.isPending && (
        <Box
          width="100%"
          height="75vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      )}

      {collectionsInfiniteQuery.isError && (
        <Box
          width="100%"
          height="75vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography color={(theme) => theme.palette.error.main}>
            {" "}
            {collectionsInfiniteQuery.error.message}{" "}
          </Typography>
        </Box>
      )}

      {!collectionsInfiniteQuery.isPending &&
      !collectionsInfiniteQuery.isError &&
      collectionsData.length === 0 ? (
        <Grid
          item
          xs={12}
          sx={{
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: (theme) => theme.palette.secondary.main,
            borderRadius: 1,
          }}
        >
          <Typography variant="body2">
            You haven't created any collections yet
          </Typography>
        </Grid>
      ) : (
        <>
          <InfiniteScroll
            dataLength={collectionsData.length}
            next={collectionsInfiniteQuery.fetchNextPage}
            hasMore={!!collectionsInfiniteQuery.hasNextPage}
            loader={
              <Box sx={{ pt: 2, pb: 2 }}>
                <LinearProgress />
              </Box>
            }
            style={{ overflow: "visible" }}
            // ^ Required to ensure framer-motion scale animation applied to library cards displays properly
          >
            <Grid container sx={{ pt: 1, pb: 2 }} spacing={3}>
              {collectionsData.map((list, index) => (
                <Grid item xs={12} key={list.list_id}>
                  <CollectionCover
                    list={list}
                    reverse={index % 2 === 0}
                    onClick={() => setActiveCollectionId(list.list_id)}
                  />
                </Grid>
              ))}
            </Grid>
          </InfiniteScroll>

          {activeCollectionId && (
            <Dialog
              open={!!activeCollectionId}
              fullScreen={fullscreen}
              onClose={() => setActiveCollectionId(null)}
              fullWidth
              PaperProps={{
                sx: {
                  maxWidth: { md: "80%" },
                  width: "auto",
                  backgroundImage: "none",
                },
              }}
            >
              <DialogContent sx={{ p: 1.5 }}>
                <Collection
                  list={activeCollection}
                  onDelete={() => setActiveCollectionId(null)}
                />
              </DialogContent>
            </Dialog>
          )}
        </>
      )}

      {collectionsData.length === 0 && !fullscreen && (
        <Box sx={{ position: "absolute", top: 75, right: 15 }}>
          <Lottie
            animationData={arrowAnimation}
            loop={true}
            style={{
              width: 200,
              height: 200,
              transform: "scaleY(-1) rotate(+20deg)",
            }}
          />
        </Box>
      )}
    </>
  );
}
