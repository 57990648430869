import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import SettingsMenu from "./SettingsMenu.js";
import useApi from "../hooks/useApi.js";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import SettingsIcon from "@mui/icons-material/Settings";

import { useAuth } from "../context/authContext.js";
import Avatar from "@mui/material/Avatar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddContent from "./AddContentButton.js";
import SearchBar from "./SearchBar.js";
import LibraryModal from "../features/library/LibraryModal.js";
import ProfilePreferences from "../features/profile/ProfilePreferences.js";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogContent,
  SvgIcon,
  Tooltip,
  useTheme,
} from "@mui/material";

import LogoSmallDark from "../assets/LogoLargeDark.svg";
import LogoSmallLight from "../assets/LogoLargeLight.svg";
import { ReactComponent as TrackIcon } from "../assets/IconTrack.svg";
import { ReactComponent as CurateIcon } from "../assets/IconCurate.svg";
import { ReactComponent as ExploreIcon } from "../assets/IconExplore.svg";

const drawerWidth = 240;

function ResponsiveDrawer({
  handleDrawerToggle, // handled via route-specific app bars
  mobileOpen,
  handleDrawerTransitionEnd,
  handleDrawerClose,
  newUser,
}) {
  // Custom hooks
  const theme = useTheme();
  const { user } = useAuth();
  const { addStatus } = useApi();
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = (path) => location.pathname === path;
  const params = new URLSearchParams(location.search);
  const isNewUser = params.get("newUser") === "true";
  console.log("responsive drawer isNewUser = ", isNewUser);

  // States
  const [libraryAddition, setLibraryAddition] = useState(null);
  const [settingsMenuAnchorEl, setSettingsMenuAnchorEl] = useState(null);
  const openSettingsMenu = Boolean(settingsMenuAnchorEl);
  const [openSearchbarModal, setOpenSearchbarModal] = useState(false);
  const [openProfilePrefs, setOpenProfilePrefs] = useState(isNewUser);

  // Handlers
  const handleSettingsMenuOpen = (event) => {
    if (settingsMenuAnchorEl !== event.currentTarget) {
      setSettingsMenuAnchorEl(event.currentTarget);
    }
  };

  const handleSettingsMenuClose = () => {
    setSettingsMenuAnchorEl(null);
  };

  const addStatusMutation = useMutation({
    mutationFn: ({ selectedOption, status, createJournalEntry }) =>
      addStatus(selectedOption.tmdb_id, status, createJournalEntry),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["movie", "status", variables.selectedOption.tmdb_id],
      });
      queryClient.invalidateQueries({
        queryKey: ["movie", "statuses", "infinite"],
      });
      queryClient.invalidateQueries({
        queryKey: ["movie", "journal", variables.selectedOption.tmdb_id],
      });
      queryClient.invalidateQueries({
        queryKey: ["movie", "journal", "infinite"],
      });
      setLibraryAddition(variables.selectedOption);
      // Decided to not open library modal after quick add to avoid duplication of journal entries
      toast.success(data.message);
    },
    onError: (error, variables) => {
      toast.error(error.response?.data?.message);
      // }
    },
  });

  const handleQuickAdd = (selectedOption, status) => {
    setOpenSearchbarModal(false);
    addStatusMutation.mutate({
      selectedOption,
      status: status,
      createJournalEntry: true,
    });
  };

  const handleSearchSelect = (selection) => {
    setOpenSearchbarModal(false);
    addStatusMutation.mutate({
      selectedOption: selection,
      status: user.preferences.addButton, // either "finished" or "queued"
      createJournalEntry: true,
    });
    // setLibraryAddition(selection);
  };

  const handleAddContent = () => {
    setOpenSearchbarModal(true);
  };

  const handleCloseProfilePrefs = () => {
    params.delete("newUser");
    navigate(
      { pathname: location.pathname, search: params.toString() },
      { replace: true }
    );
    setOpenProfilePrefs(false);
  };

  const trianglePointerStyle = {
    position: "absolute",
    right: -10,
    top: "50%",
    transform: "translateY(-50%) rotate(45deg)",
    width: 20,
    height: 20,
    bgcolor: "background.paper",
    zIndex: 0,
  };

  const drawer = (
    <Box display="flex" flexDirection="column" height="100%">
      <Box flexGrow={1}>
        <List>
          <ListItem sx={{ pt: 2 }}>
            <ListItemIcon>
              <img
                src={
                  theme.palette.mode === "dark" ? LogoSmallDark : LogoSmallLight
                }
                alt="Curate Logo"
                style={{ width: 150 }}
              />
            </ListItemIcon>
          </ListItem>

          <ListItem key="profile" disablePadding sx={{ pt: 2 }}>
            <ListItemButton component={Link} to="/app/profile">
              <ListItemIcon>
                <Avatar
                  alt={user.givenName}
                  src={user?.picture}
                  sx={{ display: "flex", bgcolor: "tertiary.main" }}
                />
              </ListItemIcon>
              <ListItemText
                primary={user.givenName}
                primaryTypographyProps={{
                  fontWeight: isActive("/app/profile") ? "bold" : "undefined",
                  color: (theme) =>
                    isActive("/app/profile")
                      ? theme.palette.primary.main
                      : "undefined",
                }}
              />
              {isActive("/app/profile") && (
                <Box component="span" sx={trianglePointerStyle} />
              )}
            </ListItemButton>
          </ListItem>
        </List>

        <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/app/library">
              <ListItemIcon>
                <SvgIcon
                  sx={{
                    color: isActive("/app/library")
                      ? "primary.main"
                      : undefined,
                  }}
                >
                  <TrackIcon />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText
                primary="Track"
                primaryTypographyProps={{
                  fontWeight: isActive("/app/library") ? "bold" : "undefined",
                  color: (theme) =>
                    isActive("/app/library")
                      ? theme.palette.primary.main
                      : "undefined",
                }}
              />
              {isActive("/app/library") && (
                <Box component="span" sx={trianglePointerStyle} />
              )}
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton component={Link} to="/app/collections">
              <ListItemIcon>
                <SvgIcon
                  sx={{
                    color: isActive("/app/collections")
                      ? "primary.main"
                      : undefined,
                  }}
                >
                  <CurateIcon />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText
                primary="Curate"
                primaryTypographyProps={{
                  fontWeight: isActive("/app/collections")
                    ? "bold"
                    : "undefined",
                  color: (theme) =>
                    isActive("/app/collections")
                      ? theme.palette.primary.main
                      : "undefined",
                }}
              />
              {isActive("/app/collections") && (
                <Box component="span" sx={trianglePointerStyle} />
              )}
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <Tooltip title="Coming soon!">
              <span>
                <ListItemButton component={Link} to="/app/explore" disabled>
                  <ListItemIcon>
                    <SvgIcon
                      sx={{
                        color: isActive("/app/explore")
                          ? "primary.main"
                          : undefined,
                      }}
                    >
                      <ExploreIcon />
                    </SvgIcon>
                  </ListItemIcon>
                  <ListItemText
                    primary="Explore"
                    primaryTypographyProps={{
                      fontWeight: isActive("/app/explore")
                        ? "bold"
                        : "undefined",
                      color: (theme) =>
                        isActive("/app/explore")
                          ? theme.palette.primary.main
                          : "undefined",
                    }}
                  />
                  {isActive("/app/explore") && (
                    <Box component="span" sx={trianglePointerStyle} />
                  )}
                </ListItemButton>
              </span>
            </Tooltip>
          </ListItem>
        </List>
      </Box>

      <List>
        {/* <ListItem disablePadding>
          <ListItemButton component={Link} to="/app/test">
            <ListItemIcon>
              <ScienceIcon />
            </ListItemIcon>
            <ListItemText primary="Test" />
          </ListItemButton>
        </ListItem> */}

        <ListItem disablePadding>
          <ListItemButton onClick={handleSettingsMenuOpen}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="side bar"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: 0,
              backgroundImage: "none",
              backgroundColor: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: 0,
              backgroundImage: "none",
              backgroundColor: (theme) => theme.palette.secondary.main,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <SettingsMenu
        anchorElement={settingsMenuAnchorEl}
        open={openSettingsMenu}
        handleClose={handleSettingsMenuClose}
        handleOpenPrefs={() => setOpenProfilePrefs(true)}
      />
      <AddContent onSelect={handleAddContent} />
      <Dialog
        open={openSearchbarModal}
        onClose={() => setOpenSearchbarModal(false)}
        fullWidth
        TransitionProps={{
          onEnter: () => {
            // Reset state in SearchBarContents
          },
        }}
        maxWidth="sm"
        sx={{
          "& .MuiDialog-container": {
            alignItems: "flex-start",
            paddingTop: "25vh",
          },
        }}
        disableRestoreFocus
        // Todo: remove when live, currently needed to fix a bug that stops textfields
        // within dialogs from autofocusing with React strict mode enabled
      >
        <DialogContent
          sx={{
            border: "none",
          }}
        >
          <Typography sx={{ mb: -2.6 }} variant="body2">
            Add movie to library...
          </Typography>
          <SearchBar
            onQuickAdd={handleQuickAdd}
            onSelect={handleSearchSelect}
          />
        </DialogContent>
      </Dialog>

      {libraryAddition && (
        <LibraryModal
          open={!!libraryAddition}
          tmdbId={libraryAddition?.tmdb_id}
          onClose={() => setLibraryAddition(null)}
        />
      )}

      <ProfilePreferences
        open={openProfilePrefs}
        handleClose={handleCloseProfilePrefs}
        newUser={isNewUser}
      />
    </>
  );
}

export default ResponsiveDrawer;
