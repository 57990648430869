import React from "react";
import {
  Stack,
  Chip,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  Rating,
} from "@mui/material";
import { formatDate, getYear, reactionOptions } from "../../utils/utils.js";

function JournalList({ entry, onClick }) {
  return (
    <Card onClick={onClick} elevation={0}>
      <CardActionArea>
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: "0px 4px",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {" "}
              {entry?.movie_title}{" "}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{ fontStyle: "italic" }}
            >
              {" "}
              {getYear(entry?.movie_release_date)}{" "}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            justifyContent={"flex-end"}
            alignItems="center"
          >
            <Chip
              label={entry?.date ? formatDate(entry?.date) : "Undated"}
              size="small"
              sx={{
                backgroundColor: "transparent",
                color: "text.secondary",
                display: { xs: "none", md: "inline-flex" },
              }}
            />

            {entry?.reactions &&
              entry.reactions.map((reaction, index) => {
                const option = reactionOptions.find(
                  (option) => option.label === reaction
                );
                return (
                  <Chip
                    key={index}
                    icon={
                      option.icon || (
                        <span role="img" aria-label={option.label}>
                          {option.emoji}
                        </span>
                      )
                    }
                    label={reaction}
                    size="small"
                    sx={{
                      display: { xs: "none", lg: "inline-flex" },
                    }}
                  />
                );
              })}

            <Rating value={entry?.rating} readOnly precision={0.5} />
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default JournalList;
