import ScheduleIcon from "@mui/icons-material/Schedule";

export const getCollectionSortByOptions = () => [
  {
    label: "Updated",
    value: "updated",
    icon: <ScheduleIcon fontSize="small" />,
  },
  {
    label: "Created",
    value: "created",
    icon: <ScheduleIcon fontSize="small" />,
  },
];
