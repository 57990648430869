import React, { createContext, useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useApi from "../hooks/useApi.js";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

// Create a context
const AuthContext = createContext();

// Hook for child components to get the auth object and re-render when it changes
export const useAuth = () => {
  return useContext(AuthContext);
};

// Provider component that wraps your app and makes auth object available to any child component that calls useAuth()
export const AuthProvider = ({ children }) => {
  const { authenticateUser, logoutUser } = useApi();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const protectedRoutes = ["/app/*"];
  const isProtectedRoute = protectedRoutes.some((route) =>
    location.pathname.startsWith(route)
  );

  const userAuthQuery = useQuery({
    queryKey: ["currentUser"],
    queryFn: () => authenticateUser(),
    enabled: true,
    retry: false,
    refetchOnWindowFocus: false,
  });

  const login = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/oauth2/authorize`;
  };

  const logoutMutation = useMutation({
    mutationFn: () => logoutUser(),
    onSuccess: () => {
      queryClient.cancelQueries();
      queryClient.removeQueries();
      navigate("/");
    },
    onError: (error) => {
      toast.error("Error logging out: ", error);
    },
  });

  // The value prop of the provider will be our context value
  // In this case, the current user and functions to manipulate it
  const value = {
    user: userAuthQuery.data?.userDetails || null,
    isCheckingAuth: userAuthQuery.isLoading,
    login,
    logout: logoutMutation.mutate,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
