import { useCallback } from "react";
import axios from "axios";
import { useThemeContext } from "../context/themeContext";
import { useNavigate } from "react-router-dom";

export default function useApi() {
  const { setMode } = useThemeContext();
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;

  // Metadata endpoints

  const fetchMovieMetaData = useCallback(
    async (id) => {
      try {
        const jwt = localStorage.getItem("jwt");
        const response = await axios.get(
          `${API_BASE_URL}/movie/metadata?q=${id}`,
          {
            withCredentials: true,
            // headers: { "X-CSRF-TOKEN": csrfToken },
            headers: { Authorization: `Bearer ${jwt}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [API_BASE_URL]
  );

  const fetchBookMetaData = useCallback(
    async (id) => {
      try {
        const jwt = localStorage.getItem("jwt");
        console.log(`fetchBookMetaData fired`);
        const response = await axios.get(
          `${API_BASE_URL}/book/metadata?q=${id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Bearer ${jwt}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [API_BASE_URL]
  );

  const fetchMovieAutocomplete = useCallback(
    async (searchInput) => {
      try {
        const jwt = localStorage.getItem("jwt");
        const response = await axios.get(
          `${API_BASE_URL}/movie/autocomplete?q=${searchInput}`,
          {
            withCredentials: true,
            // headers: { "X-CSRF-TOKEN": csrfToken },
            headers: { Authorization: `Bearer ${jwt}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error("Error fetching autocomplete data: ", error);
      }
    },
    [API_BASE_URL]
  );

  const fetchMovieSearch = useCallback(
    async (searchInput) => {
      const jwt = localStorage.getItem("jwt");
      const response = await axios.get(
        `${API_BASE_URL}/movie/search?q=${searchInput}`,
        {
          withCredentials: true,
          // headers: { "X-CSRF-TOKEN": csrfToken },
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );
      return response.data;
    },
    [API_BASE_URL]
  );

  // Library endpoints

  const fetchJournal = useCallback(
    async ({
      pageParam = 1,
      perPage,
      sortBy,
      sortDescending,
      filters,
      searchTerm,
    }) => {
      console.log(`fetchJournal fired`);
      const jwt = localStorage.getItem("jwt");
      const filterParams = filters
        ? filters.reduce((acc, filter) => {
            const filterKey = `${filter.type.toLowerCase()}_filter`;
            if (Array.isArray(filter.value)) {
              // Join array values for genres and decades into a comma-separated string
              acc[filterKey] = filter.value.join(",");
            } else {
              // Directly assign the value for rating
              acc[filterKey] = filter.value;
            }
            return acc;
          }, {})
        : {}; // If no filters are provided, set to empty object

      const response = await axios.get(`${API_BASE_URL}/movie/journal`, {
        params: {
          page: pageParam,
          per_page: perPage,
          sort_by: sortBy,
          sort_descending: sortDescending, // axios converts boolean parameter to string: "true" or "false"
          search_term: searchTerm,
          ...filterParams,
        },
        withCredentials: true,
        // headers: { "X-CSRF-TOKEN": csrfToken },
        headers: { Authorization: `Bearer ${jwt}` },
      });
      return {
        items: response.data.items,
        nextPage: response.data.hasMore ? pageParam + 1 : undefined,
        hasMore: response.data.hasMore,
      };
    },
    [API_BASE_URL]
  );

  const editEntry = useCallback(
    async (entryId, editedEntry) => {
      console.log("editEntry fired");
      const jwt = localStorage.getItem("jwt");
      const response = await axios.put(
        `${API_BASE_URL}/movie/journal/${entryId}`,
        editedEntry,
        {
          withCredentials: true,
          // headers: { "X-CSRF-TOKEN": csrfToken },
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );
      return response.data;
    },
    [API_BASE_URL]
  );

  const addEntry = useCallback(
    async (newEntry) => {
      console.log("addEntry fired");
      const jwt = localStorage.getItem("jwt");
      const response = await axios.post(
        `${API_BASE_URL}/movie/journal`,
        newEntry,
        {
          withCredentials: true,
          // headers: { "X-CSRF-TOKEN": csrfToken },
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );
      return response.data;
    },
    [API_BASE_URL]
  );

  const deleteEntry = useCallback(
    async (entryId) => {
      console.log("deleteEntry fired");
      const jwt = localStorage.getItem("jwt");
      const response = await axios.delete(
        `${API_BASE_URL}/movie/journal/${entryId}`,
        {
          withCredentials: true,
          // headers: { "X-CSRF-TOKEN": csrfToken },
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );
      return response.data;
    },
    [API_BASE_URL]
  );

  const fetchJournalForMovie = useCallback(
    async (tmdbId) => {
      console.log(`fetchJournalForMovie fired for tmdbId = ${tmdbId}`);
      const jwt = localStorage.getItem("jwt");
      const response = await axios.get(
        `${API_BASE_URL}/movie/journalformovie/${tmdbId}`,
        {
          withCredentials: true,
          // headers: { "X-CSRF-TOKEN": csrfToken },
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );
      return response.data;
    },
    [API_BASE_URL]
  );

  // Statistics endpoints

  const fetchStats = useCallback(
    async (userId) => {
      try {
        const jwt = localStorage.getItem("jwt");
        const response = await axios.get(`${API_BASE_URL}/statistics/movie`, {
          withCredentials: true,
          // headers: { "X-CSRF-TOKEN": csrfToken },
          headers: { Authorization: `Bearer ${jwt}` },
        });
        return response.data;
      } catch (error) {
        console.error(`fetchStats error, failed to fetch statistics | `, error);
        return null;
      }
    },
    [API_BASE_URL]
  );

  // Status endpoints

  const fetchStatuses = useCallback(
    async ({
      pageParam = 1,
      perPage,
      status,
      sortDescending,
      filters,
      searchTerm,
    }) => {
      console.log(`fetchStatuses fired`);
      const jwt = localStorage.getItem("jwt");
      const filterParams = filters
        ? filters.reduce((acc, filter) => {
            const filterKey = `${filter.type.toLowerCase()}_filter`;
            if (Array.isArray(filter.value)) {
              // Join array values for genres and decades into a comma-separated string
              acc[filterKey] = filter.value.join(",");
            } else {
              // Directly assign the value for rating
              acc[filterKey] = filter.value;
            }
            return acc;
          }, {})
        : {}; // If no filters are provided, set to empty object

      const response = await axios.get(`${API_BASE_URL}/movie/statuses`, {
        params: {
          page: pageParam,
          per_page: perPage,
          status: status,
          sort_descending: sortDescending, // axios converts boolean parameter to string: "true" or "false"
          search_term: searchTerm,
          ...filterParams,
        },
        withCredentials: true,
        // headers: { "X-CSRF-TOKEN": csrfToken },
        headers: { Authorization: `Bearer ${jwt}` },
      });
      return {
        items: response.data.items,
        nextPage: response.data.hasMore ? pageParam + 1 : undefined,
        hasMore: response.data.hasMore,
      };
    },
    [API_BASE_URL]
  );

  const addStatus = useCallback(
    async (tmdbId, status, createJournalEntry) => {
      console.log("addStatus fired");
      const jwt = localStorage.getItem("jwt");
      // N.B. tmdb id not movie id!
      try {
        const newStatus = {
          movie_id: tmdbId,
          status: status,
          createJournalEntry: createJournalEntry,
        };
        const response = await axios.post(
          `${API_BASE_URL}/movie/statuses`,
          newStatus,
          {
            withCredentials: true,
            // headers: { "X-CSRF-TOKEN": csrfToken },
            headers: { Authorization: `Bearer ${jwt}` },
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    [API_BASE_URL]
  );

  const editStatus = useCallback(
    async ({ tmdbId, status }) => {
      console.log("editStatus fired");
      const jwt = localStorage.getItem("jwt");
      try {
        const response = await axios.put(
          `${API_BASE_URL}/movie/status/${tmdbId}`,
          { status }, // status is a string, curly braces required to pass it as a json object
          {
            withCredentials: true,
            // headers: { "X-CSRF-TOKEN": csrfToken },
            headers: { Authorization: `Bearer ${jwt}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [API_BASE_URL]
  );

  const deleteStatus = useCallback(
    async (tmdbId) => {
      console.log(`deleteStatus fired`);
      const jwt = localStorage.getItem("jwt");
      const response = await axios.delete(
        `${API_BASE_URL}/movie/status/${tmdbId}`,
        {
          withCredentials: true,
          // headers: { "X-CSRF-TOKEN": csrfToken },
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );
      return response.data;
    },
    [API_BASE_URL]
  );

  const fetchStatus = useCallback(
    async (tmdbId) => {
      console.log(`fetchStatus fired for tmdbId = ${tmdbId}`);
      const jwt = localStorage.getItem("jwt");
      try {
        const response = await axios.get(
          `${API_BASE_URL}/movie/status/${tmdbId}`,
          {
            withCredentials: true,
            // headers: { "X-CSRF-TOKEN": csrfToken },
            headers: { Authorization: `Bearer ${jwt}` },
          }
        );
        return response.data;
      } catch (error) {
        if (
          error.response &&
          error.response.status === 404 &&
          error.response.data.message === "Status not found"
        ) {
          console.log(
            "^ This is not an error; movie just does not have a status yet"
          );
          return { status: "none" }; // return status of "none" if a status object does not yet exist for movie
        } else {
          throw error; // rethrow other errors
        }
      }
    },
    [API_BASE_URL]
  );

  const addMovie = useCallback(
    async (tmdbSearchObject) => {
      console.log(`addMovie fired`);
      const jwt = localStorage.getItem("jwt");
      try {
        await axios.post(`${API_BASE_URL}/movie/add`, tmdbSearchObject, {
          withCredentials: true,
          // headers: { "X-CSRF-TOKEN": csrfToken },
          headers: { Authorization: `Bearer ${jwt}` },
        });
      } catch (error) {
        console.error(error);
      }
    },
    [API_BASE_URL]
  );

  const fetchCollections = useCallback(
    async ({
      listId = null,
      isDefault = true,
      sortDescending = true,
      sortBy = "updated",
      searchTerm = "",
      pageParam = 1,
      perPage = 5,
    } = {}) => {
      console.log(`fetchCollections fired`);
      const jwt = localStorage.getItem("jwt");
      let endpoint = `${API_BASE_URL}/lists`; // Default endpoint for lists without specific ID
      if (listId) {
        endpoint = `${API_BASE_URL}/lists/${listId}`;
      } else {
        // Only append the query parameter if no listId is provided
        const params = new URLSearchParams();
        params.append("is_default", isDefault);
        params.append("sort_descending", sortDescending);
        params.append("sort_by", sortBy);
        params.append("search_term", searchTerm);
        params.append("page", pageParam);
        params.append("per_page", perPage);
        endpoint += `?${params.toString()}`;
      }
      try {
        const response = await axios.get(endpoint, {
          withCredentials: true,
          headers: { Authorization: `Bearer ${jwt}` },
        });
        return {
          items: response.data.items,
          nextPage: response.data.hasMore ? pageParam + 1 : undefined,
          hasMore: response.data.hasMore,
        };
      } catch (error) {
        console.error("fetchLists error: ", error);
        throw error;
      }
    },
    [API_BASE_URL]
  );

  const fetchList = useCallback(
    async (listId) => {
      try {
        console.log(`fetchList fired`);
        const jwt = localStorage.getItem("jwt");
        const response = await axios.get(`${API_BASE_URL}/lists/${listId}`, {
          withCredentials: true,
          headers: { Authorization: `Bearer ${jwt}` },
        });
        return response.data;
      } catch (error) {
        console.error("fetchList error: ", error);
        throw error;
      }
    },
    [API_BASE_URL]
  );

  const fetchDefaultList = useCallback(
    async (content_type, list_type, month, year) => {
      console.log(`fetchDefaultList fired`);
      const jwt = localStorage.getItem("jwt");
      try {
        const response = await axios.get(`${API_BASE_URL}/defaultlist`, {
          params: {
            content_type,
            list_type,
            month,
            year,
          },
          withCredentials: true,
          headers: { Authorization: `Bearer ${jwt}` },
        });
        return response.data;
      } catch (error) {
        console.error("fetchList error: ", error);
        throw error;
      }
    },
    [API_BASE_URL]
  );

  const addMovieToList = useCallback(
    async ({ listId, tmdbId }) => {
      console.log(`addMovieToList fired`);
      const jwt = localStorage.getItem("jwt");
      try {
        const response = await axios.post(
          `${API_BASE_URL}/lists/${listId}`,
          { tmdbId },
          {
            withCredentials: true,
            headers: { Authorization: `Bearer ${jwt}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error("editList error: ", error);
        throw error;
      }
    },
    [API_BASE_URL]
  );

  const removeMovieFromList = useCallback(
    async ({ listId, tmdbId }) => {
      console.log(`removeMovieFromList fired`);
      const jwt = localStorage.getItem("jwt");
      try {
        const response = await axios.delete(
          `${API_BASE_URL}/lists/${listId}/movie/${tmdbId}`,
          {
            withCredentials: true,
            headers: { Authorization: `Bearer ${jwt}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error("removeMovieFromList error: ", error);
        throw error;
      }
    },
    [API_BASE_URL]
  );

  const replaceMovieInList = useCallback(
    async ({ listId, oldTmdbId, newTmdbId }) => {
      try {
        console.log(`replaceMovieInList fired`);
        const jwt = localStorage.getItem("jwt");
        const newValue = { newTmdbId };
        const response = await axios.put(
          `${API_BASE_URL}/lists/${listId}/movie/${oldTmdbId}`,
          newValue,
          {
            withCredentials: true,
            headers: { Authorization: `Bearer ${jwt}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error("replaceMovieInList error: ", error);
        throw error;
      }
    },
    [API_BASE_URL]
  );

  const reorderMoviesInList = useCallback(
    async ({ listId, movies }) => {
      console.log(`reorderMoviesInList fired for list ID: ${listId}`);
      const jwt = localStorage.getItem("jwt");
      try {
        const response = await axios.put(
          `${API_BASE_URL}/lists/${listId}`,
          { movies },
          {
            withCredentials: true,
            headers: { Authorization: `Bearer ${jwt}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error("reorderMoviesInList error: ", error);
        throw error;
      }
    },
    [API_BASE_URL]
  );

  const editListDetails = useCallback(
    async ({ listId, title, description, isRanked, size }) => {
      console.log(`editListDetails fired for list ID: ${listId}`);
      const jwt = localStorage.getItem("jwt");
      try {
        const response = await axios.put(
          `${API_BASE_URL}/lists/${listId}`,
          { title, description, isRanked, size },
          {
            withCredentials: true,
            headers: { Authorization: `Bearer ${jwt}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error("editListDetails error: ", error);
        throw error;
      }
    },
    [API_BASE_URL]
  );

  const createList = useCallback(
    async (newList) => {
      console.log(`createList fired`);
      const jwt = localStorage.getItem("jwt");
      console.log("newList received by createList = ", newList);
      try {
        const response = await axios.post(
          `${API_BASE_URL}/movie/lists`,
          newList,
          {
            withCredentials: true,
            headers: { Authorization: `Bearer ${jwt}` },
          }
        );
        return response.data;
      } catch (error) {
        console.error("createList error: ", error);
        throw error;
      }
    },
    [API_BASE_URL]
  );

  const deleteList = useCallback(
    async (listId) => {
      console.log("deleteList fired");
      const jwt = localStorage.getItem("jwt");
      const response = await axios.delete(`${API_BASE_URL}/lists/${listId}`, {
        withCredentials: true,
        // headers: { "X-CSRF-TOKEN": csrfToken },
        headers: { Authorization: `Bearer ${jwt}` },
      });
      return response.data;
    },
    [API_BASE_URL]
  );

  const fetchActivityFeed = useCallback(
    async ({ pageParam = 1, perPage = 10, self = false }) => {
      console.log("fetchActivityFeed fired");
      const jwt = localStorage.getItem("jwt");
      const response = await axios.get(`${API_BASE_URL}/feed`, {
        params: {
          page: pageParam,
          per_page: perPage,
          self: self,
        },
        withCredentials: true,
        headers: { Authorization: `Bearer ${jwt}` },
      });
      return {
        items: response.data.items,
        nextPage: response.data.has_more ? pageParam + 1 : undefined,
        hasMore: response.data.hasMore,
      };
    },
    [API_BASE_URL]
  );

  const checkUsernameAvailability = useCallback(
    async (username) => {
      console.log("getUserByUsername fired");
      const jwt = localStorage.getItem("jwt");
      const response = await axios.get(`${API_BASE_URL}/check-username`, {
        params: {
          username: username,
        },
        withCredentials: true,
        headers: { Authorization: `Bearer ${jwt}` },
      });
      return response.data.isAvailable;
    },
    [API_BASE_URL]
  );

  const fetchUserCounts = useCallback(async () => {
    console.log("fetchUserCounts fired");
    const jwt = localStorage.getItem("jwt");
    const response = await axios.get(`${API_BASE_URL}/user-counts`, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${jwt}` },
    });
    console.log(response.data);
    return response.data;
  }, [API_BASE_URL]);

  const editProfile = useCallback(
    async ({ username, bio, darkModePref, addButtonPref }) => {
      console.log("editProfile fired");
      const jwt = localStorage.getItem("jwt");
      const response = await axios.put(
        `${API_BASE_URL}/edit-profile`,
        {
          username,
          bio,
          darkModePref,
          addButtonPref,
        },
        {
          withCredentials: true,
          headers: { Authorization: `Bearer ${jwt}` },
        }
      );
      return response.data;
    },
    [API_BASE_URL]
  );

  const authenticateUser = useCallback(async () => {
    try {
      console.log("authenticateUser fired");
      const jwt = localStorage.getItem("jwt");
      const response = await axios.get(`${API_BASE_URL}/token/auth`, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${jwt}` },
      });

      const { isAuthenticated, userDetails } = response.data;
      console.log("isAuthenticated = ", isAuthenticated);
      if (isAuthenticated) {
        setMode(userDetails.preferences.darkMode ? "dark" : "light");
      } else {
        localStorage.removeItem("jwt");
        navigate("/");
      }
      return response.data;
    } catch (error) {
      console.error("authenticateUser error:", error);
      localStorage.removeItem("jwt");
      throw error;
    }
  }, [setMode, navigate, API_BASE_URL]);

  const logoutUser = useCallback(async () => {
    const jwt = localStorage.getItem("jwt");
    if (!jwt) return;

    console.log("logoutUser fired");
    const response = await axios.post(`${API_BASE_URL}/oauth2/clear`, null, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${jwt}` },
    });
    localStorage.removeItem("jwt");
    return response.data;
  }, [API_BASE_URL]);

  return {
    fetchMovieAutocomplete,
    fetchMovieMetaData,
    fetchBookMetaData,
    fetchJournal,
    fetchStatuses,
    editEntry,
    fetchMovieSearch,
    addEntry,
    fetchJournalForMovie,
    fetchStatus,
    deleteEntry,
    fetchStats,
    fetchCollections,
    fetchList,
    editStatus,
    addMovieToList,
    removeMovieFromList,
    replaceMovieInList,
    reorderMoviesInList,
    deleteList,
    createList,
    editListDetails,
    addStatus,
    addMovie,
    deleteStatus,
    fetchActivityFeed,
    fetchDefaultList,
    checkUsernameAvailability,
    fetchUserCounts,
    editProfile,
    authenticateUser,
    logoutUser,
  };
}
