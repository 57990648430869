import React, { useCallback } from "react";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, InputAdornment } from "@mui/material";

const Search = styled("div")(({ theme, highlight }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.secondary.main,
  marginRight: theme.spacing(2),
  width: "auto",
  height: "38px",
  display: "flex",
  border: highlight ? `1px solid ${theme.palette.primary.main}` : undefined, // Conditional border
}));

const SearchIconWrapper = styled("div")(({ theme, highlight }) => ({
  paddingLeft: theme.spacing(1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: highlight ? theme.palette.primary.main : undefined, // Conditional icon color
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    paddingLeft: "38px",
    transition: theme.transitions.create("width"),
    fontSize: "0.875rem",
    width: 0,
    "&:focus, &:not(:placeholder-shown)": {
      width: "10ch", // Default width on focus for smaller screens
      // pr: "24px",
      [theme.breakpoints.up("md")]: {
        width: "15ch", // Larger width on focus for wider screens
      },
    },
  },
}));

export default function SearchFilter({ searchTerm, onSearchTermChange }) {
  const [isFocused, setIsFocused] = React.useState(false);
  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Escape") {
        onSearchTermChange({ target: { value: "" } });
        event.target.blur();
      }
    },
    [onSearchTermChange]
  );

  const handleClear = () => {
    onSearchTermChange({ target: { value: "" } });
  };

  return (
    <Search highlight={!!searchTerm}>
      <SearchIconWrapper highlight={!!searchTerm}>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        id="search-library-by-title"
        placeholder={searchTerm ? "" : "Filter by title"}
        inputProps={{ "aria-label": "search", autoComplete: "off" }}
        value={searchTerm}
        onChange={onSearchTermChange}
        onKeyDown={handleKeyDown}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        endAdornment={
          isFocused || searchTerm ? (
            <InputAdornment position="end" sx={{ pr: 1 }}>
              <IconButton
                aria-label="clear search"
                onClick={handleClear}
                edge="end"
                size="small"
                sx={{ visibility: searchTerm ? "visible" : "hidden" }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ) : undefined
        }
      />
    </Search>
  );
}
