import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const OAuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const isNewUser = params.get("new_user");
    console.log("isNewUser flag in OAuthCallback route = ", isNewUser);

    if (token) {
      localStorage.setItem("jwt", token);
      queryClient.invalidateQueries(["currentUser"]);
      if (isNewUser === "True") {
        navigate("/app/library?newUser=true"); // Todo: create a welcome page for new users
      } else {
        navigate("/app/library/");
      }
    } else {
      navigate("/login");
    }
  }, [location, navigate, queryClient]);

  return null; // Todo: return a loading message/spinner here
};

export default OAuthCallback;
