// External imports
import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Container,
  useTheme,
  Stack,
  Avatar,
  Typography,
  IconButton,
  Tooltip,
  Popover,
} from "@mui/material";
import GridViewIcon from "@mui/icons-material/GridView";
import MenuIcon from "@mui/icons-material/Menu";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MovieRoundedIcon from "@mui/icons-material/MovieRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import LiveTvRoundedIcon from "@mui/icons-material/LiveTvRounded";
import PodcastsRoundedIcon from "@mui/icons-material/PodcastsRounded";
import InfoIcon from "@mui/icons-material/Info";
import AddIcon from "@mui/icons-material/Add";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";

// Internal imports
import HideOnScroll from "../../components/HideOnScroll.js";
import SearchAppBar from "../../components/SearchFilter.js";
import { useAuth } from "../../context/authContext.js";
import Settings from "@mui/icons-material/Settings.js";
import Bio from "../profile/Bio.js";
import AddList from "./AddNew.js";
import {
  getContentOptions,
  getContentOptionsArray,
} from "../../utils/utils.js";
import { getCollectionSortByOptions } from "../../utils/collectionUtils.js";
import ListMenu from "../../components/ListMenu.js";
import SortMenu from "../../components/SortMenu.js";
import {
  HeaderButton,
  HeaderToggleButtonGroup,
  HeaderToggleButton,
} from "../../components/CustomMUI.js";

const CollectionsHeader = ({
  toggleDrawer,
  content,
  onContentChange,
  searchTerm,
  sortBy,
  onSortByChange,
  sortDescending,
  toggleSortDescending,
  onSearchTermChange,
  setNewCollectionId,
}) => {
  const [addListAnchorEl, setAddListAnchorEl] = useState(null);
  const [contentAnchorEl, setContentAnchorEl] = useState(null);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const drawerWidth = 240;
  const contentOptions = getContentOptions();
  const contentOptionsArray = getContentOptionsArray();
  const sortByOptions = getCollectionSortByOptions();
  const currentSortBy = sortByOptions.find((option) => option.value === sortBy);

  const handleAddListOpen = (event) => setAddListAnchorEl(event.currentTarget);
  const handleAddListClose = () => setAddListAnchorEl(null);
  const handleContentClick = (event) => {
    setContentAnchorEl(event.currentTarget);
  };
  const handleSortClick = (event) => {
    setSortAnchorEl(event.currentTarget);
  };
  const handleContentSelect = (option) => (event) => {
    // This function needs to accept an event even though it is not used as that is how the onClick prop of the listMenu component is configured
    onContentChange(option);
    setContentAnchorEl(null);
  };

  const handleSortSelect = (option) => {
    onSortByChange(null, option);
    setSortAnchorEl(null);
  };

  return (
    <>
      <Toolbar sx={{ mt: 2.5, mb: 2.5 }} />
      {/* The above toolbar acts as a spacer, whatever padding is applied to the below appbar
      needs to be applied as margin to the above spacer, added 0.5 to give space to show librarycard hover scale effects */}
      <HideOnScroll>
        <AppBar
          color="inherit"
          sx={{
            boxShadow: "none",
            backgroundImage: "none",
            width: { md: `calc(100% - ${drawerWidth}px )` },
            ml: { md: `${drawerWidth}px` },
            pt: 2,
            pb: 2,
            overflowX: "auto",
          }}
        >
          <Container maxWidth="lg">
            <Toolbar disableGutters>
              <HeaderToggleButton
                // This is the menu button which only displays when the sidebar is hidden
                aria-label="open drawer"
                edge="start"
                onClick={toggleDrawer}
                size="small"
                value="menu"
                sx={{
                  mr: 2,
                  display: { md: "none" },
                  "&.MuiButtonBase-root.MuiToggleButton-root": {
                    border: "none",
                    borderRadius: 1,
                  },
                }}
              >
                <MenuIcon />
              </HeaderToggleButton>

              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  flexShrink: 0,
                  justifyContent: "flex-start",
                }}
              >
                <HeaderButton
                  startIcon={contentOptions[content].icon}
                  // endIcon={<ExpandMoreIcon />}
                  onClick={handleContentClick}
                  sx={{
                    mr: 2,
                  }}
                >
                  {contentOptions[content].label}
                </HeaderButton>

                <HeaderButton
                  startIcon={sortDescending ? <SouthIcon /> : <NorthIcon />}
                  onClick={handleSortClick}
                  sx={{
                    mr: 2,
                  }}
                >
                  {currentSortBy.label}
                </HeaderButton>
              </Box>

              <SearchAppBar
                searchTerm={searchTerm}
                onSearchTermChange={onSearchTermChange}
              />

              <Stack direction="row" spacing={1} alignItems="center">
                <Tooltip title="Create new">
                  <HeaderToggleButton
                    size="small"
                    onClick={handleAddListOpen}
                    value="create-new-button"
                    sx={{
                      mr: 1,
                      "&.MuiButtonBase-root.MuiToggleButton-root": {
                        border: "none",
                        borderRadius: 1,
                      },
                    }}
                  >
                    <AddIcon />
                  </HeaderToggleButton>
                </Tooltip>
                {/* <Tooltip title="More">
                  <HeaderToggleButton
                    size="small"
                    // onClick={handleMoreMenuOpen}
                    value="more-button"
                    sx={{
                      mr: 1,
                      "&.MuiButtonBase-root.MuiToggleButton-root": {
                        border: "none",
                        borderRadius: 1,
                      },
                    }}
                  >
                    <MoreHorizIcon />
                  </HeaderToggleButton>
                </Tooltip> */}
              </Stack>
            </Toolbar>
          </Container>

          <Popover
            id="Create-new-collection"
            open={Boolean(addListAnchorEl)}
            anchorEl={addListAnchorEl}
            onClose={handleAddListClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))", // adds shadow around menu
                  mt: 1.5,
                  "&::before": {
                    // Creates triangular pointer visually connecting menu to anchor element
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              },
            }}
          >
            <AddList
              onClose={handleAddListClose}
              onAdd={(listId) => setNewCollectionId(listId)}
            />
          </Popover>
        </AppBar>
      </HideOnScroll>

      <ListMenu
        anchorEl={contentAnchorEl}
        onClose={() => setContentAnchorEl(null)}
        onSelect={handleContentSelect}
        options={contentOptionsArray}
      />

      <SortMenu
        anchorEl={sortAnchorEl}
        onClose={() => setSortAnchorEl(null)}
        onSelect={handleSortSelect}
        sortByValue={sortBy}
        toggleValue={sortDescending}
        onToggle={toggleSortDescending}
        sortByOptions={sortByOptions}
      />
    </>
  );
};

export default CollectionsHeader;
