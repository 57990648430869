import React from "react";
import {
  Box,
  MenuItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Button,
  Menu,
} from "@mui/material";

const CheckboxFilterMenu = ({
  options,
  selectedOptions,
  onChange,
  onApply,
  onClear,
  anchorEl,
  onClose,
}) => (
  <Menu
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={onClose}
    slotProps={{
      paper: {
        elevation: 0,
        sx: {
          overflow: "auto",
          maxHeight: 350, // Set a maximum height
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))", // adds shadow around menu
          mt: 1.5,
          "&::before": {
            // Creates triangular pointer visually connecting menu to anchor element
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            left: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      },
    }}
  >
    <Box sx={{ maxHeight: 250, overflow: "auto" }}>
      {options.map((option) => (
        <MenuItem
          key={option.value}
          onClick={() => onChange(option.value)}
          dense
        >
          <ListItemIcon>
            <Checkbox checked={selectedOptions.includes(option.value)} />
          </ListItemIcon>
          <ListItemText primary={option.label} />
        </MenuItem>
      ))}
    </Box>
    <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2, mx: 2 }}>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={onClear}
        sx={{ mr: 1 }}
      >
        Clear
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={onApply}
        sx={{ ml: 1 }}
      >
        Apply
      </Button>
    </Box>
  </Menu>
);

export default CheckboxFilterMenu;
