import { Grid } from "@mui/material";
import React from "react";
import Collection from "../collections/Collection";

export default function ProfilePicks({ top1List, top3List, top5List }) {
  return (
    <Grid container sx={{ pt: 1, pb: 2 }} spacing={2}>
      <Grid item xs={12} md={4}>
        <Collection
          display="inline"
          list={top1List}
          backgroundColor={(theme) => theme.palette.secondary.main}
        />
      </Grid>

      <Grid item xs={12} md={8}>
        <Collection
          display="inline"
          list={top3List}
          backgroundColor={(theme) => theme.palette.secondary.main}
        />
      </Grid>

      <Grid item xs={12}>
        <Collection
          display="inline"
          list={top5List}
          backgroundColor={(theme) => theme.palette.secondary.main}
        />
      </Grid>
    </Grid>
  );
}
