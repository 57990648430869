import { Box, Divider, Popover, Stack, Typography } from "@mui/material";
import React from "react";

export default function Bio({ open, anchorEl, onClose }) {
  return (
    <Popover
      id="bio-popover"
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))", // adds shadow around menu
            mt: 1.5,
            "&::before": {
              // Creates triangular pointer visually connecting menu to anchor element
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 30,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        },
      }}
    >
      <Box sx={{ p: 2, maxWidth: "300px" }}>
        <Stack direction="row" spacing={3}>
          <Stack direction="column" spacing={0}>
            <Typography>
              {" "}
              <b>299</b>{" "}
            </Typography>
            <Typography> followers </Typography>
          </Stack>
          <Stack direction="column" spacing={0}>
            <Typography>
              {" "}
              <b>374</b>{" "}
            </Typography>
            <Typography> following </Typography>
          </Stack>
        </Stack>
        <Divider sx={{ mb: 1, mt: 1 }} />
        <Typography>
          Wannabe screenwriter (yet to write a thing) and Chrisopher Nolan
          fan-boi.
        </Typography>
      </Box>
    </Popover>
  );
}
