import React from "react";
import {
  Stack,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Grid,
  Typography,
  Box,
  Chip,
  useTheme,
  Skeleton,
} from "@mui/material";
import DateSelectionButton from "../../components/DateSelectionButton";
import HoverRating from "../../components/HoverRating";
import EditIcon from "@mui/icons-material/Edit";
import { getStatusOptions, reactionOptions } from "../../utils/utils";

export default function JournalEntryFixed({
  entry,
  onEdit,
  isLoading = false,
}) {
  const theme = useTheme();
  const statusOptions = getStatusOptions(theme);
  const status = statusOptions[entry.status];

  const isEmpty =
    entry.rating === null && entry.reactions === null && entry.notes === null;
  // console.log("isEmpty = ", isEmpty);

  if (isLoading)
    return (
      <Card>
        <CardContent
          sx={{
            padding: 2,
            "&:last-child": {
              paddingBottom: 2, // Change this value to your desired padding
            },
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Skeleton width="70%" />
            </Grid>

            <Grid item xs={9} sx={{ position: "relative" }}>
              <Skeleton width="70%" />
              <Skeleton width="50%" />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );

  return (
    <Card>
      {/* <CardActionArea onClick={onEdit}> */}
      <CardContent
        sx={{
          padding: 2,
          "&:last-child": {
            paddingBottom: 2, // Change this value to your desired padding
          },
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ p: "4px 0 4px" }}
            >
              {React.cloneElement(status.icon, {
                sx: { color: status.color },
              })}
              <DateSelectionButton
                value={entry.date} // the date is always converted to a dayjs object before passing to the MUI DatePicker
                disabled
              />
            </Stack>
          </Grid>

          <Grid item xs={9} sx={{ position: "relative" }}>
            {isEmpty && (
              <Box
                sx={{
                  pt: "7px",
                }}
                display="flex"
                alignItems="center"
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.disabled",
                  }}
                >
                  {entry.status === "finished"
                    ? "Add rating, reactions and/or notes..."
                    : "Add notes..."}
                </Typography>
              </Box>
            )}

            <Stack direction="column" spacing={1}>
              {entry.rating && (
                <HoverRating value={entry.rating} readOnly={true} />
              )}

              {entry.reactions && (
                <Box
                  sx={{
                    flexWrap: "wrap",
                    width: entry.rating === null ? "85%" : "100%",
                  }}
                >
                  {entry.reactions.map((reaction, index) => {
                    const option = reactionOptions.find(
                      (option) => option.label === reaction
                    );
                    return (
                      <Chip
                        key={index}
                        label={reaction}
                        icon={
                          option.icon || (
                            <span role="img" aria-label={option.label}>
                              {option.emoji}
                            </span>
                          )
                        }
                        size="small"
                        sx={{
                          "&.MuiChip-root": {
                            mb: 0.5,
                            mt: 0.5,
                            mr: 1,
                          },
                        }}
                      />
                    );
                  })}
                </Box>
              )}

              {entry.notes && (
                <Typography
                  variant="body2"
                  sx={{
                    pt: "6px",
                    width:
                      entry.rating === null && entry.reactions === null
                        ? "85%"
                        : "100%",
                  }}
                >
                  {entry.notes}
                </Typography>
              )}
            </Stack>

            <Tooltip title="Edit">
              <IconButton
                onClick={onEdit}
                sx={{ p: 0.5, position: "absolute", top: 9, right: 0 }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </CardContent>
      {/* </CardActionArea> */}
    </Card>
  );
}
