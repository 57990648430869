import React, { useState } from "react";
import LibraryModal from "../features/library/LibraryModal.js";
import {
  Grid,
  Box,
  LinearProgress,
  Typography,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import useApi from "../hooks/useApi.js";
import InfiniteScroll from "react-infinite-scroll-component";
import LibraryHeader from "../features/library/LibraryHeader.js";
import { useInfiniteQuery } from "@tanstack/react-query";
import LibraryList from "../features/library/LibraryList.js";
import JournalList from "../features/library/JournalList.js";
import MetaDataTile from "../components/MetaDataTile";
import arrowAnimation from "../assets/down-right-arrow.json";

export default function Library({ toggleDrawer }) {
  const { fetchStatuses, fetchJournal } = useApi();
  const dummyFetch = () => Promise.resolve({ items: [], nextPage: undefined });

  const [content, setContent] = useState("movies");
  const [status, setStatus] = useState("finished");
  const [sortBy, setSortBy] = useState("watch_date");
  const [sortDescending, setSortDescending] = useState(true);
  const [view, setView] = useState("grid");
  const [filters, setFilters] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeItem, setActiveItem] = useState({
    tmdbId: undefined,
    entryId: undefined,
  });

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const handleSortByChange = (event, newSortBy) => {
    if (newSortBy !== null) {
      setSortBy(newSortBy);
    }
  };

  const toggleSortDescending = () => {
    setSortDescending(!sortDescending);
  };

  const handleContentChange = (newValue) => {
    setContent(newValue);
  };

  const handleStatusChange = (newValue) => {
    setStatus(newValue);
  };

  const handleFiltersChange = (newValue) => {
    setFilters(newValue);
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const statusesInfiniteQuery = useInfiniteQuery({
    queryKey: [
      "movie",
      "statuses",
      "infinite",
      { sortDescending, filters, searchTerm, status },
    ],
    queryFn:
      status === "finished"
        ? dummyFetch
        : ({ pageParam = 1 }) =>
            fetchStatuses({
              pageParam,
              perPage: 24,
              sortDescending,
              filters,
              searchTerm,
              status: status,
            }),
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });

  const movieStatuses =
    statusesInfiniteQuery.data?.pages.flatMap((page) => page.items) || [];

  const journalInfiniteQuery = useInfiniteQuery({
    queryKey: [
      "movie",
      "journal",
      "infinite",
      { sortBy, sortDescending, filters, searchTerm, status },
    ],
    queryFn:
      status !== "finished"
        ? dummyFetch
        : ({ pageParam = 1 }) =>
            fetchJournal({
              pageParam,
              perPage: 24,
              sortDescending,
              sortBy,
              filters,
              searchTerm,
              status: status,
            }),
    getNextPageParam: (lastPage) => lastPage.nextPage,
    refetchOnMount: false,
  });

  const movieJournal =
    journalInfiniteQuery.data?.pages.flatMap((page) => page.items) || [];

  return (
    <>
      <LibraryHeader
        content={content}
        onContentChange={handleContentChange}
        status={status}
        onStatusChange={handleStatusChange}
        view={view}
        onViewChange={handleViewChange}
        sortBy={sortBy}
        onSortByChange={handleSortByChange}
        sortDescending={sortDescending}
        toggleSortDescending={toggleSortDescending}
        toggleDrawer={toggleDrawer}
        activeFilters={filters}
        onActiveFiltersChange={handleFiltersChange}
        searchTerm={searchTerm}
        onSearchTermChange={handleSearchTermChange}
      />

      {status === "finished" && (
        <>
          {journalInfiniteQuery.isPending && (
            <Box
              width="100%"
              height="75vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          )}

          {journalInfiniteQuery.isError && (
            <Box
              width="100%"
              height="75vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography color={(theme) => theme.palette.error.main}>
                {" "}
                {journalInfiniteQuery.error.message}{" "}
              </Typography>
            </Box>
          )}

          {!journalInfiniteQuery.isPending &&
          !journalInfiniteQuery.isError &&
          movieJournal.length === 0 ? (
            <Grid
              item
              xs={12}
              sx={{
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: (theme) => theme.palette.secondary.main,
                borderRadius: 1,
              }}
            >
              <Typography variant="body2">
                You currently have no movies in your {status} list
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <InfiniteScroll
                dataLength={movieJournal.length}
                next={journalInfiniteQuery.fetchNextPage}
                hasMore={!!journalInfiniteQuery.hasNextPage}
                loader={
                  <Box sx={{ pt: 2, pb: 2 }}>
                    <LinearProgress />
                  </Box>
                }
                style={{ overflow: "visible" }}
                // Overflow visbile required to ensure framer-motion scale animation applied to library cards displays properly
              >
                <Grid container spacing={view === "list" ? 1 : 2}>
                  {movieJournal.map((entry) => {
                    return (
                      <Grid
                        item
                        key={`journal-${entry.journal_id}`}
                        xs={view === "list" ? 12 : 4}
                        sm={view === "list" ? 12 : 4}
                        md={view === "list" ? 12 : 3}
                        lg={view === "list" ? 12 : 2}
                        xl={view === "list" ? 12 : 2}
                      >
                        <motion.div
                          layout="position"
                          whileHover={{
                            scale: 1.02,
                            transition: { duration: 0.2 },
                          }}
                        >
                          {view === "list" ? (
                            <JournalList
                              entry={entry}
                              onClick={() =>
                                setActiveItem({
                                  tmdbId: entry.movie_tmdb_id,
                                  entryId: entry.journal_id,
                                })
                              }
                            />
                          ) : (
                            <MetaDataTile
                              tmdbId={entry.movie_tmdb_id}
                              onClick={() =>
                                setActiveItem({
                                  tmdbId: entry.movie_tmdb_id,
                                  entryId: entry.journal_id,
                                })
                              }
                            />
                          )}
                        </motion.div>
                      </Grid>
                    );
                  })}
                </Grid>
              </InfiniteScroll>
            </Grid>
          )}
        </>
      )}

      {status !== "finished" && (
        <>
          {statusesInfiniteQuery.isPending && (
            <Box
              width="100%"
              height="75vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          )}

          {statusesInfiniteQuery.isError && (
            <Box
              width="100%"
              height="75vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography color={(theme) => theme.palette.error.main}>
                {" "}
                {statusesInfiniteQuery.error.message}{" "}
              </Typography>
            </Box>
          )}

          {!statusesInfiniteQuery.isPending &&
          !statusesInfiniteQuery.isError &&
          status !== "finished" &&
          movieStatuses.length === 0 ? (
            <Grid
              item
              xs={12}
              sx={{
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: (theme) => theme.palette.secondary.main,
                borderRadius: 1,
              }}
            >
              <Typography variant="body2">
                You currently have no movies in your {status} list
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <InfiniteScroll
                dataLength={movieStatuses.length}
                next={statusesInfiniteQuery.fetchNextPage}
                hasMore={!!statusesInfiniteQuery.hasNextPage}
                loader={
                  <Box sx={{ pt: 2, pb: 2 }}>
                    <LinearProgress />
                  </Box>
                }
                // scrollableTarget="scrollableDiv"
                style={{ overflow: "visible" }} // Required to ensure framer-motion scale animation applied to library cards displays properly
              >
                <Grid container spacing={view === "list" ? 1 : 2}>
                  {movieStatuses.map((movie) => {
                    return (
                      <Grid
                        item
                        key={`status-${movie.id}`}
                        xs={view === "list" ? 12 : 4}
                        sm={view === "list" ? 12 : 4}
                        md={view === "list" ? 12 : 3}
                        lg={view === "list" ? 12 : 2}
                        xl={view === "list" ? 12 : 2}
                      >
                        <motion.div
                          layout="position"
                          whileHover={{
                            scale: 1.02,
                            transition: { duration: 0.2 },
                          }}
                        >
                          {view === "list" ? (
                            <LibraryList
                              movie={movie}
                              onClick={() =>
                                setActiveItem({ tmdbId: movie.tmdbId })
                              }
                            />
                          ) : (
                            <MetaDataTile
                              tmdbId={movie.tmdbId}
                              onClick={() =>
                                setActiveItem({ tmdbId: movie.tmdbId })
                              }
                            />
                          )}
                        </motion.div>
                      </Grid>
                    );
                  })}
                </Grid>
              </InfiniteScroll>
            </Grid>
          )}
        </>
      )}

      {activeItem.tmdbId && (
        <LibraryModal
          open={!!activeItem.tmdbId}
          tmdbId={activeItem.tmdbId}
          entryId={activeItem.entryId || undefined}
          onClose={() =>
            setActiveItem({
              tmdbId: undefined,
              entryId: undefined,
            })
          }
        />
      )}

      {((status === "finished" && movieJournal.length === 0) ||
        (status !== "finished" && movieStatuses.length === 0)) && (
        <Box sx={{ position: "absolute", bottom: 30, right: 30 }}>
          <Lottie
            animationData={arrowAnimation}
            loop={true}
            style={{ width: 200, height: 200 }}
          />
        </Box>
      )}
    </>
  );
}
