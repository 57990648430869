import React, { forwardRef } from "react";
import {
  Card,
  CardActionArea,
  CardMedia,
  Skeleton,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMovieMetadata } from "../hooks/useCustomQuery";

function MetaDataTile({
  tmdbId,
  onClick,
  onHover,
  width,
  position = undefined,
  padding,
  zIndex,
  skeleton = false,
}) {
  const { data: metaData, isLoading } = useMovieMetadata(tmdbId);
  const [showOptions, setShowOptions] = React.useState(false);

  // React.useEffect(() => {
  //   console.log(`MetaDataTile mounted with tmdbId: ${tmdbId}`);
  //   return () => {
  //     console.log(`MetaDataTile unmounted with tmdbId: ${tmdbId}`);
  //   };
  // }, [tmdbId]); // Depend on tmdbId to see this log when tmdbId changes

  const cardContent = (
    <>
      {isLoading || skeleton ? (
        <CardMedia
          component={Skeleton}
          variant="rectangular"
          width="100%"
          animation={skeleton ? false : "wave"}
          sx={{
            display: "block",
            paddingTop: "150%",
            height: 0,
            borderRadius: 1,
          }}
        />
      ) : (
        metaData && (
          <CardMedia
            component="img"
            sx={{
              width: "100%",
              objectFit: "contain",
              borderRadius: 1,
              filter: position ? "brightness(60%)" : "none",
            }}
            image={`https://image.tmdb.org/t/p/w500/${metaData.poster_path}`}
            alt={`${metaData.title} poster`}
          />
        )
      )}
      {position !== null && (
        <Typography
          sx={{
            position: "absolute",
            top: 7,
            left: 10,
            color: "white",
            fontSize: "4rem",
            lineHeight: 1,
            fontWeight: "bold",
            zIndex: 1, // Ensure it's above the image
          }}
        >
          {position}
        </Typography>
      )}
    </>
  );

  return (
    <Card
      onClick={onClick}
      onMouseEnter={() => setShowOptions(true)}
      onMouseLeave={() => setShowOptions(false)}
      sx={{
        maxWidth: 300,
        position: "relative",
        width: width || undefined,
        padding: padding || undefined,
        boxShadow: padding ? "none" : "default",
        // backgroundColor: (theme) =>
        //   padding ? theme.palette.grey[100] : "inherit",
        zIndex: zIndex || undefined,
      }}
    >
      {onClick ? (
        <CardActionArea onClick={onClick}>{cardContent}</CardActionArea>
      ) : (
        cardContent
      )}
      {onHover && showOptions && (
        <Stack
          direction="column"
          spacing={1}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            // padding: "4px",
          }}
        >
          {onHover.map((option) => (
            <Tooltip placement="left" title={option.label} key={option.value}>
              <IconButton
                onClick={option.onClick}
                aria-label={option.label}
                size="small"
                sx={{ background: "rgba(255, 255, 255, 0.8)" }}
              >
                {option.icon}
              </IconButton>
            </Tooltip>
          ))}
        </Stack>
      )}
    </Card>
  );
}

export default MetaDataTile;
