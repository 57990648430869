import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  useTheme,
  useMediaQuery,
  Stack,
  Typography,
  IconButton,
  Tooltip,
  Chip,
  Box,
  Button,
  Skeleton,
} from "@mui/material";
import { useMovieMetadata } from "../hooks/useCustomQuery";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import LanguageIcon from "@mui/icons-material/Language";
import TheatersIcon from "@mui/icons-material/Theaters";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import { CircularProgress } from "@mui/material";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import useApi from "../hooks/useApi";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddTaskIcon from "@mui/icons-material/AddTask";

import {
  getYear,
  getStatusOptions,
  getStatusOptionsArray,
} from "../utils/utils";
import ListMenu from "./ListMenu";

const languageNames = new Intl.DisplayNames(["en"], { type: "language" });

function MovieCard({
  tmdbId,
  onClose,
  status,
  onStatusChange,
  disableStatusChange,
  setLogAgain,
  onDeleteStatus,
}) {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { editStatus, deleteStatus, addStatus } = useApi();
  const { data: metaData, isLoading } = useMovieMetadata(tmdbId);
  const statusOptions = getStatusOptions(theme);
  const baseStatusOptionsArray = getStatusOptionsArray(theme).filter(
    (option) => option.value !== "none" && option.value !== status
    // remove default "none" option as well as the current status
  );

  const statusOptionsDropdown = [
    ...(status !== "finished"
      ? baseStatusOptionsArray.map((option) => ({
          ...option,
          label: option.action,
        }))
      : []),
    ...(status === "finished"
      ? [
          {
            label: "Log again",
            value: "logAgain",
            icon: <AddTaskIcon />,
            color: theme.palette.success.light,
          },
        ]
      : []),
    {
      label: "Delete",
      value: "none",
      icon: <DeleteOutlineIcon />,
      color: "#bdbdbd",
    },
  ].filter(Boolean); // needed to filter out falsey values that originate due to the conditions used in constructing the array

  const currentStatus =
    status !== "loading" ? statusOptions[status] : undefined;

  const [statusAnchorEl, setStatusAnchorEl] = useState(null);

  const hours = Math.floor(metaData?.runtime / 60);
  const minutes = metaData?.runtime % 60;
  const originalLanguage = metaData?.original_language
    ? languageNames.of(metaData?.original_language)
    : "?";

  const handleStatusClick = (event) => {
    setStatusAnchorEl(event.currentTarget);
  };

  const editStatusMutation = useMutation({
    mutationFn: editStatus,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["movie", "status", tmdbId],
      });
      queryClient.invalidateQueries({
        queryKey: ["movie", "statuses", "infinite"],
      });
      queryClient.invalidateQueries({
        queryKey: ["movie", "journal", tmdbId],
      });
      queryClient.invalidateQueries({
        queryKey: ["movie", "journal", "infinite"],
      });
      onStatusChange(data.updatedStatus.status);
      toast.success(data.message);
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
    },
  });

  const deleteStatusMutation = useMutation({
    mutationFn: deleteStatus,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["movie", "status", tmdbId],
      });
      queryClient.invalidateQueries({
        queryKey: ["movie", "statuses", "infinite"],
      });
      queryClient.invalidateQueries({
        queryKey: ["movie", "journal", tmdbId],
      });
      queryClient.invalidateQueries({
        queryKey: ["movie", "journal", "infinite"],
      });
      onStatusChange("none");
      onDeleteStatus?.();
      toast.success(data.message);
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
    },
  });

  const addStatusMutation = useMutation({
    mutationFn: ({ tmdbId, status, createJournalEntry }) =>
      addStatus(tmdbId, status, createJournalEntry),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["movie", "status", tmdbId],
      });
      queryClient.invalidateQueries({
        queryKey: ["movie", "statuses", "infinite"],
      });
      queryClient.invalidateQueries({
        queryKey: ["movie", "journal", tmdbId],
      });
      queryClient.invalidateQueries({
        queryKey: ["movie", "journal", "infinite"],
      });
      onStatusChange(variables.status);
      toast.success(data.message);
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
    },
  });

  const handleStatusSelect = (option) => (event) => {
    // This function needs to accept an event even though it is not used as that
    // is how the onClick prop of the ListMenu component is configured
    setStatusAnchorEl(null);
    if (option === "logAgain") {
      setLogAgain(true);
      return; // Early return to prevent status changes
    }
    if (status !== "none" && option !== "none") {
      editStatusMutation.mutate({
        tmdbId: tmdbId,
        status: option,
      });
    }
    if (status === "none" && option !== "none") {
      addStatusMutation.mutate({
        tmdbId: tmdbId,
        status: option,
        createJournalEntry: true,
      });
    }
    if (status !== "none" && option === "none") {
      deleteStatusMutation.mutate(tmdbId);
    }
  };

  const loadingDiv = (
    <Stack direction="column" spacing={1}>
      <Skeleton width="70%" />
      <Skeleton width="60%" />
      <Skeleton width="50%" />
    </Stack>
  );

  console.log(currentStatus?.contrastText);

  return (
    <>
      <Card
        sx={{
          display: "flex",
          position: "relative",
          // backgroundImage: "none",
          width: "100%",
        }}
      >
        <CardContent
          sx={{
            width: "100%",
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <CardMedia
                    component={isLoading ? Skeleton : "img"}
                    variant={isLoading ? "rectangular" : undefined}
                    sx={{
                      width: "100%",
                      height: isLoading ? 0 : "auto",
                      pt: isLoading && "150%",
                      borderRadius: 1,
                      flexShrink: 0,
                    }}
                    image={
                      isLoading
                        ? undefined
                        : `https://image.tmdb.org/t/p/w500/${metaData?.poster_path}`
                    }
                    alt={
                      isLoading
                        ? "Movie poster loading"
                        : `${metaData?.title} poster`
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={9}
                  position="relative"
                  display="flex"
                  flexDirection="column"
                  // sx={{ border: "1px solid black" }}
                >
                  {isLoading ? (
                    loadingDiv
                  ) : (
                    <>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        flexGrow={1}
                      >
                        <Stack direction="column" spacing={1}>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={1}
                            alignItems="flex-start"
                          >
                            <Box
                              sx={{
                                "& > span": {
                                  marginRight: 2,
                                },
                              }}
                            >
                              <Typography
                                variant="h6"
                                component="span"
                                sx={{
                                  fontWeight: "bold",
                                  display: "inline-block",
                                }}
                              >
                                {metaData?.title}
                              </Typography>
                              <Box
                                component="span"
                                sx={{ whiteSpace: "nowrap" }}
                              >
                                <Typography
                                  variant="subtitle1"
                                  component="span"
                                  sx={{ marginRight: 2 }}
                                >
                                  {metaData?.director}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  component="span"
                                  color="text.secondary"
                                >
                                  <i>{getYear(metaData?.release_date)}</i>
                                </Typography>
                              </Box>
                            </Box>
                          </Stack>

                          <Stack direction="row" spacing={1}>
                            <AccessTimeFilledIcon fontSize="small" />
                            <Typography variant="body2" color="text.secondary">
                              {hours}h {minutes}m
                            </Typography>
                            <TheatersIcon fontSize="small" />
                            <Typography variant="body2" color="text.secondary">
                              {metaData?.certification}
                            </Typography>
                            <LanguageIcon fontSize="small" />
                            <Typography variant="body2" color="text.secondary">
                              {originalLanguage}
                            </Typography>
                          </Stack>

                          <Stack
                            direction="row"
                            spacing={1}
                            // sx={{ paddingTop: 1, paddingBottom: 1 }}
                            alignItems="center"
                          >
                            <TheaterComedyIcon fontSize="small" />
                            <Box
                              sx={{
                                flexWrap: "wrap",
                              }}
                            >
                              {metaData?.genres.map((genre) => (
                                <Chip
                                  key={genre.id}
                                  label={genre.name.toLowerCase()}
                                  size="small"
                                  variant="filled"
                                  sx={{
                                    "&.MuiChip-root": {
                                      mt: 0.5,
                                      mb: 0.5,
                                      mr: 1,
                                    },
                                  }}
                                />
                              ))}
                            </Box>
                          </Stack>
                        </Stack>

                        <Tooltip title="Close">
                          <IconButton
                            onClick={onClose}
                            sx={{
                              // position: "absolute",
                              // top: 16,
                              // right: 0,
                              p: 0.5,
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>

                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        sx={{ mt: 1 }}
                      >
                        {/* <Typography variant="body2" color="text.secondary">
                          {status === "finished" ? (
                            <i>What did you think? Log below!</i>
                          ) : null}
                        </Typography> */}
                        <Tooltip
                          title={
                            disableStatusChange || status === "loading"
                              ? ""
                              : "Update status"
                          }
                        >
                          <Button
                            variant="contained"
                            startIcon={currentStatus?.icon}
                            endIcon={
                              disableStatusChange ? undefined : (
                                <ExpandMoreIcon />
                              )
                            }
                            // The following three props are syntaxed as below to ensure tooltip is operational wrapped around a disabled button
                            onClick={
                              disableStatusChange || status === "loading"
                                ? undefined
                                : handleStatusClick
                            }
                            disabled={
                              disableStatusChange || status === "loading"
                            }
                            component={
                              disableStatusChange || status === "loading"
                                ? "div"
                                : undefined
                            }
                            // color={`${statusOptions[status.color]}`} Ideally I want to set the color here so that color is applied to button properly (i.e. on hover also)
                            sx={{
                              boxShadow: "none",
                              height: "38px",
                              backgroundColor: currentStatus?.color, //
                              color: currentStatus?.contrastText,
                              "&:hover": {
                                backgroundColor: currentStatus?.color, // Ensure hover state retains the same color
                              },
                              "&.Mui-disabled": {
                                pointerEvents: "auto",
                                backgroundColor: currentStatus?.color,
                                color: "white",
                                opacity: 1, // Ensures the color remains unchanged
                              },
                            }}
                          >
                            {status === "loading" ? (
                              <CircularProgress size="1rem" color="inherit" />
                            ) : (
                              currentStatus?.label
                            )}
                          </Button>
                        </Tooltip>
                      </Box>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <ListMenu
        anchorEl={statusAnchorEl}
        onClose={() => setStatusAnchorEl(null)}
        onSelect={handleStatusSelect}
        options={statusOptionsDropdown}
      />
    </>
  );
}

export default MovieCard;
