import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import BookCard from "../components/BookCard";

export default function Test() {
  const [open, setOpen] = useState(true);
  const [status, setStatus] = useState("none");
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleStatusChange = (option) => {
    setStatus(option);
  };
  return (
    <>
      <p> Testing, testing, 123... </p>

      <Button onClick={() => setOpen(true)}>
        <Typography>Open modal</Typography>
      </Button>

      <Dialog
        open={open}
        fullWidth
        onClose={() => setOpen(false)}
        scroll="body"
        PaperProps={{
          sx: {
            maxWidth: 700,
            boxShadow: "none",
            overflow: "visible",
            backgroundColor: fullscreen
              ? alpha(theme.palette.common.black, 0.5)
              : "transparent",
          },
        }}
      >
        <Box sx={{ px: "5%", py: "5%" }}>
          <Box sx={{ mb: 2 }}>
            <BookCard
              olWid="OL4297222W"
              onClose={() => setOpen(false)}
              status={status}
              onStatusChange={handleStatusChange}
              // setLogAgain={setLogAgain}
              // onDeleteStatus={handleCloseModal}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
