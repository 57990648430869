import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  Stack,
  Typography,
  useTheme,
  Box,
  Popover,
  LinearProgress,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import useApi from "../hooks/useApi.js";
import { getStatusOptionsArray, getYear } from "../utils/utils.js";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";

export default function SearchBar({
  onQuickAdd,
  onSelect,
  enableQuickAdd = true,
}) {
  const theme = useTheme();
  const statusOptionsArray = getStatusOptionsArray(theme).filter(
    (option) => option.value !== "none"
  );
  const { fetchMovieAutocomplete, fetchMovieSearch, addMovie } = useApi();
  const [options, setOptions] = useState([]); // Autocomplete dropdown options
  const [searchInput, setSearchInput] = useState(""); // Input value of searchbar
  const [searchValue, setSearchValue] = useState("");
  const [focusedOption, setFocusedOption] = useState(null); // Index of focused option
  const [openAutocompleteDropdown, setOpenAutocompleteDropdown] =
    useState(true);
  const [infoPopupAnchorEl, setInfoPopupAnchorEl] = useState(null);
  const openInfoPopup = Boolean(infoPopupAnchorEl);

  useEffect(() => {
    if (searchInput.length > 2) {
      // When the search input is more than two characters, the options dropdown
      // is updated with autocomplete data fetched from the autocomplete endpoint
      // every time the user types a character
      const loadAutocompleteData = async () => {
        try {
          const data = await fetchMovieAutocomplete(searchInput);
          setOptions([
            ...data,
            {
              label: `Search for "${searchInput}"`,
              searchFor: true,
              value: searchInput,
            },
          ]);
        } catch (error) {
          console.error("Error fetching autocomplete data:", error);
        }
      };

      loadAutocompleteData();
    } else {
      setOptions([]); // Clear options if the input is cleared or less than 3 characters
    }
  }, [searchInput, fetchMovieAutocomplete]);

  const searchQuery = useQuery({
    queryKey: ["movieSearch", searchValue],
    queryFn: () => fetchMovieSearch(searchValue),
    // Only want query to run when handleSearchFor is fired, hence need to disable the following:
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: 0, // Disable caching
    staleTime: 0, // Ensure refetch each time query runs, desired if not caching
  });

  const handleSearchFor = (searchValue) => {
    setSearchValue(searchValue);
    setFocusedOption(null);
    setOpenAutocompleteDropdown(false);
  };

  useEffect(() => {
    // Needed to ensure searchQuery is fetched only when searchValue changes
    if (searchValue) {
      searchQuery.refetch();
    }
  }, [searchValue, searchQuery]);

  const handleSearchSelection = async (result) => {
    try {
      await addMovie(result);
      const selectedOption = {
        tmdb_id: result.id,
        title: result.title,
        releaseYear: getYear(result.release_date),
      };
      onSelect(selectedOption);
    } catch (error) {
      console.error("Error adding movie:", error);
    }
  };

  return (
    <Box>
      {enableQuickAdd && (
        <Box display="flex" justifyContent="flex-end" sx={{ mb: 1 }}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            aria-owns={openInfoPopup ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={(event) => setInfoPopupAnchorEl(event.currentTarget)}
            onMouseLeave={() => setInfoPopupAnchorEl(null)}
          >
            <Typography variant="body2">Quick add</Typography>
            <OfflineBoltIcon fontSize="small" />
          </Stack>
        </Box>
      )}

      <Autocomplete
        id="autocomplete-search-bar"
        freeSolo
        clearOnEscape
        fullWidth
        blurOnSelect
        inputValue={searchInput}
        options={options}
        open={openAutocompleteDropdown}
        getOptionLabel={(option) => {
          if (
            typeof option === "object" &&
            (option.searchFor || option.title)
            // Required because when user presses enter without interacting with autocomplete dropdown, the option itself is passed
            // to getOptionLabel as a string and therefore doesn't have .value and .title properties
          ) {
            return option.searchFor ? option.value : option.title;
          }
          return option;
        }}
        renderOption={(props, option, { selected }) => {
          // Determines how the options are displayed in the Autocomplete dropdown
          const isFocused =
            focusedOption !== null && focusedOption === options.indexOf(option);

          if (option.searchFor) {
            return (
              <li {...props} onClick={() => handleSearchFor(option.value)}>
                <Typography fontWeight="bold">{option.label}</Typography>
              </li>
            );
          }
          return (
            <li
              {...props}
              onClick={() => onSelect(option)}
              key={option.tmdb_id}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>{option.title}</Typography>
                  <Typography variant="body2" fontStyle="italic">
                    {option.releaseYear}
                  </Typography>
                </Stack>
                {enableQuickAdd && isFocused && <OfflineBoltIcon />}
              </Stack>
            </li>
          );
        }}
        filterOptions={(options) => options} // Needed to enable fuzzy matching to work, see here: https://stackoverflow.com/questions/68505566/autocomplete-not-rendering-as-expected-material-ui
        onInputChange={(event, newInputValue) => {
          setSearchInput(newInputValue); // Update the search input based on user input
          setOpenAutocompleteDropdown(true); // Needed to ensure dropdown is visible when user starts typing
        }}
        onHighlightChange={(event, option) => {
          setFocusedOption(option !== null ? options.indexOf(option) : null);
        }}
        onKeyDown={(event) => {
          if (focusedOption !== null) {
            const selectedOption = options[focusedOption];
            if (event.key === "Enter") {
              if (selectedOption.searchFor) {
                handleSearchFor(selectedOption.value);
              } else {
                onSelect(selectedOption);
              }
            } else if (enableQuickAdd) {
              if (event.key === "q") {
                onQuickAdd(selectedOption, "queued");
              } else if (event.key === "s") {
                onQuickAdd(selectedOption, "started");
              } else if (event.key === "a") {
                onQuickAdd(selectedOption, "abandoned");
              } else if (event.key === "f") {
                onQuickAdd(selectedOption, "finished");
              }
            }
          } else if (event.key === "Enter") {
            handleSearchFor(searchInput);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            hiddenLabel={true}
            onBlur={() => {
              setOpenAutocompleteDropdown(false);
            }}
            size="small"
            variant="filled"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
          />
        )}
      />

      {searchQuery.isLoading && <LinearProgress />}

      {searchQuery.isSuccess && searchQuery.data.results.length === 0 && (
        <Typography
          variant="body2"
          sx={{
            padding: theme.spacing(2, 1),
            color: theme.palette.text.secondary,
          }}
        >
          No results found
        </Typography>
      )}

      {searchQuery.isSuccess && searchQuery.data.results.length > 0 && (
        <Box component="ul" sx={{ padding: 0, listStyle: "none" }}>
          {searchQuery.data.results.map((result) => (
            <Box
              component="li"
              onClick={() => handleSearchSelection(result)}
              key={result.id}
              sx={{
                display: "flex",
                width: "100%",
                cursor: "pointer",
                padding: theme.spacing(1),
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>{result.title}</Typography>
                <Typography variant="body2" fontStyle="italic">
                  {getYear(result.release_date)}
                </Typography>
              </Stack>
            </Box>
          ))}
        </Box>
      )}

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={openInfoPopup}
        anchorEl={infoPopupAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={() => setInfoPopupAnchorEl(null)}
        disableRestoreFocus
      >
        <Box sx={{ p: 2 }} maxWidth={300}>
          <Typography variant="body2">
            If you see <OfflineBoltIcon fontSize="body2" /> next to an option,
            you can quickly add it to your library by pressing
          </Typography>
          <Stack spacing={1} sx={{ mt: 1 }}>
            {statusOptionsArray.map((option) => (
              <Stack spacing={1} direction="row" key={option.value}>
                {React.cloneElement(option.icon, {
                  fontSize: "small",
                  sx: { color: option.color },
                })}
                <Typography variant="body2">
                  {" "}
                  <b>{Array.from(option.action.toLowerCase())[0]}</b> to{" "}
                  {option.action.toLowerCase()}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Box>
      </Popover>
    </Box>
  );
}
